import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as fromProducts from './products.actions';
import {
  selectProducts, selectProductsErrors,
  selectProductsIsLoaded,
  selectProductsIsLoading, selectProductsTotalCount

} from './products.selectors';
import { ApiResponsePaginated } from '../store.model';
import {
  Product,
  ProductDeleteDto,
  ProductInsertDto, ProductUpdateDto,
  Request
} from '@exe/client/client-web/core/supabase';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class ProductsFacadeService {
  constructor(private readonly store: Store, private actions$: Actions) {}

  clearProducts(): void {
    return this.store.dispatch(fromProducts.clearProducts());
  }

  fetchProducts(getProductsRequestDto: Request): void {
    getProductsRequestDto = {
      ...getProductsRequestDto,
      select: `*, lastActionEvent:actionEvents!products_lastActionEventId_fkey!outer(*, action:actions!inner(*), user:users!inner(*))`
    }
    return this.store.dispatch(fromProducts.fetchProducts({ payload: getProductsRequestDto }));
  }

  isProductsLoading$(): Observable<boolean> {
    return this.store.select(selectProductsIsLoading).pipe(
      filter(productsIsLoading => !!productsIsLoading)
    );
  }

  isProductsLoaded$(): Observable<boolean> {
    return this.store.select(selectProductsIsLoaded).pipe(
      filter(productsIsLoaded => !!productsIsLoaded)
    );
  }

  getProducts$(): Observable<ApiResponsePaginated<Product>> {
    return this.store.select(selectProducts).pipe(
      filter(products => !!products)
    );
  }

  getProductsErrors$(): Observable<HttpErrorResponse[]> {
    return this.store.select(selectProductsErrors).pipe(
      filter(errors => !!errors)
    );
  }

  getProductsTotalCount$(): Observable<number> {
    return this.store.select(selectProductsTotalCount).pipe(
      filter(productsTotalCount => !!productsTotalCount)
    );
  }

  addProduct(createProductRequestDto: ProductInsertDto): Observable<void> {
    this.store.dispatch(fromProducts.addProduct({ payload: createProductRequestDto }));

    return this.actions$.pipe(
      ofType(fromProducts.addProductSuccess),
      first()
    ) as Observable<void>
  }

  updateProduct(productUpdateDto: ProductUpdateDto): Observable<void> {
    this.store.dispatch(fromProducts.updateProduct({ payload: productUpdateDto }));
    return this.actions$.pipe(
      ofType(fromProducts.updateProductSuccess),
      first()
    ) as Observable<void>
  }

  deleteProducts(productDeleteDto: ProductDeleteDto): Observable<void> {
    this.store.dispatch(fromProducts.deleteProducts({ payload: productDeleteDto }));

    return this.actions$.pipe(
      ofType(fromProducts.deleteProductsSuccess),
      first()
    ) as Observable<void>
  }
}
