import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  Request,
  Product,
  ProductInsertDto,
  ProductDeleteDto, ProductUpdateDto
} from '@exe/client/client-web/core/supabase';
import { ApiResponsePaginated } from '../store.model';

const prefix = '[Products]';

export const clearProducts = createAction(`${prefix} Clear products`);
export const fetchProducts = createAction(`${prefix} Fetch products`, props<{ payload: Request }>());
export const fetchProductsSuccess = createAction(`${prefix} Fetch products success`, props<{ payload: ApiResponsePaginated<Product> }>());
export const fetchProductsFailure = createAction(`${prefix} Fetch products failure`, props<{ payload: HttpErrorResponse }>());

export const addProduct = createAction(`${prefix} Add product`, props<{ payload: ProductInsertDto }>());
export const addProductSuccess = createAction(`${prefix} Add product success`);
export const addProductFailure = createAction(`${prefix} Add product failure`, props<{ payload: HttpErrorResponse }>());

export const updateProduct = createAction(`${prefix} Update product`, props<{ payload: ProductUpdateDto }>());
export const updateProductSuccess = createAction(`${prefix} Update product success`);
export const updateProductFailure = createAction(`${prefix} Update product failure`, props<{ payload: HttpErrorResponse }>());

export const deleteProducts = createAction(`${prefix} Delete products`, props<{ payload: ProductDeleteDto }>());
export const deleteProductsSuccess = createAction(`${prefix} Delete products success`);
export const deleteProductsFailure = createAction(`${prefix} Delete products failure`, props<{ payload: HttpErrorResponse }>());
