export const URL_TREE = {
  AUTH: {
    URL: 'auth',
    LOGIN: {
      URL: 'login'
    }
  },
  DASHBOARD: {
    URL: 'dashboard',
    ACTIONS: {
      URL: 'actions',
      TABLE: {
        URL: 'table'
      },
    },
    PRODUCTION: {
      URL: 'production'
    },
    VALUATION: {
      URL: 'valuation'
    },
    STATISTICS: {
      URL: 'statistics'
    },
    TRACE: {
      URL: 'trace',
      ORDERS: {
        URL: 'orders'
      }
    },
    CONFIGURATION: {
      URL: 'configuration',
      USERS: {
        URL: 'users',
        TABLE: {
          URL: 'table'
        }
      },
      ACTIONS: {
        URL: 'actions',
        TABLE: {
          URL: 'table'
        }
      },
      CUSTOMERS: {
        URL: 'customers',
        TABLE: {
          URL: 'table'
        }
      },
      PROJECTS: {
        URL: 'projects',
        TABLE: {
          URL: 'table'
        }
      }
    }
  },
  USER_CHECK: {
    URL: 'user-check',
  },
  NOT_FOUND: {
    URL: 'not-found'
  },
  NOT_AUTHORIZED: {
    URL: 'not-authorized'
  }
}

export interface Translation {
  pl: string;
  en: string;
}

export interface TranslationMap {
  [key: string]: Translation;
}

export const TRANSLATIONS: TranslationMap = {
  dashboard: {
    pl: 'Panel główny',
    en: 'Dashboard'
  },
  trace: {
    pl: 'Śledzenie',
    en: 'Trace'
  },
  actions: {
    pl: 'Akcje',
    en: 'Actions'
  },
  table: {
    pl: 'Tabela',
    en: 'Table'
  },
  production: {
    pl: 'Produkcja',
    en: 'Production'
  },
  valuation: {
    pl: 'Wycena',
    en: 'Valuation'
  },
  statistics: {
    pl: 'Statystyki',
    en: 'Statistics'
  },
  configuration: {
    pl: 'Konfiguracja',
    en: 'Configuration'
  },
  users: {
    pl: 'Użytkownicy',
    en: 'Users'
  },
  customers: {
    pl: 'Klienci',
    en: 'Customers'
  },
  projects: {
    pl: 'Projekty',
    en: 'Projects'
  },
  orders: {
    pl: 'Lista zleceń',
    en: 'Orders list'
  },
  'order-details': {
    pl: 'Lista produktów',
    en: 'Products list'
  },
  product: {
    pl: 'Produkt',
    en: 'Product'
  },
  auth: {
    pl: 'Autoryzacja',
    en: 'Authorization'
  },
  login: {
    pl: 'Logowanie',
    en: 'Login'
  }
};
