import { Injectable } from '@angular/core';
import {
  AuthTokenResponsePassword,
  createClient,
  SignInWithPasswordCredentials,
  SupabaseClient
} from '@supabase/supabase-js';
import { Operators, Request, UploadedFile } from './supabase.model';
import { catchError, from, map, mergeMap, Observable, of, switchMap } from 'rxjs';
import { uuid } from '@supabase/supabase-js/dist/main/lib/helpers';
import { PdfToImg } from "pdftoimg-js/browser";

@Injectable({
  providedIn: 'root',
})
export class SupabaseService {

  public client: SupabaseClient = createClient(
    'https://lklxyfbideiwghodhjde.supabase.co',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxrbHh5ZmJpZGVpd2dob2RoamRlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjEzNzk0MDcsImV4cCI6MjAzNjk1NTQwN30.sljKMx_lFkp2FP42foQaw4ZC_q5bx8cN-rNTPxKDanA'
  );

  login(credentials: SignInWithPasswordCredentials): Promise<AuthTokenResponsePassword> {
    return this.client.auth.signInWithPassword(credentials)
  }

  logout(): Promise<any> {
    return this.client.auth.signOut();
  }

  buildSelectQuery(request: Request) {
    let query = this.client.from(request.table!)
      .select(request.select as "*", { count: 'exact' });

    for (const filter of request?.filters!) {
      switch (filter.operator) {
        case Operators.EQ:
          query = query.eq(filter.column, filter.value);
          break;
        case Operators.NEQ:
          query = query.neq(filter.column, filter.value);
          break;
        case Operators.GT:
          query = query.gt(filter.column, filter.value);
          break;
        case Operators.GTE:
          query = query.gte(filter.column, filter.value);
          break;
        case Operators.LT:
          query = query.lt(filter.column, filter.value);
          break;
        case Operators.LTE:
          query = query.lte(filter.column, filter.value);
          break;
        case Operators.LIKE:
          query = query.like(filter.column, filter.value);
          break;
        case Operators.ILIKE:
          query = query.ilike(filter.column, filter.value);
          break;
        case Operators.IS:
          query = query.is(filter.column, filter.value);
          break;
        default:
          break;
      }
    }

    if (request.order) {
      query = query.order(request.order!.column, request.order!.options)
    }

    query = query.range(request.skip!, request.skip! + request.take!);

    return query;
  }


  replaceFile(bucket: string, path: string, file: File): Observable<UploadedFile> {
    return from(this.client.storage.from(bucket).remove([path])).pipe(
      catchError(() => of(null)),
      switchMap(() => this.uploadToStorage(bucket, path, file)),
      switchMap(uploadResult => this.handleNonPdfFile(file, uploadResult))
    );
  }

  uploadFiles(bucket: string, path: string, files: File[]): Observable<UploadedFile> {
    return from(files).pipe(
      mergeMap(file => this.uploadSingleFile(bucket, path, file))
    );
  }

  private uploadSingleFile(bucket: string, path: string, file: File): Observable<UploadedFile> {
    const fileId = uuid();
    const filePath = `${path}/${fileId}/${file.name}`;

    return this.uploadToStorage(bucket, filePath, file).pipe(
      mergeMap(uploadResult => {
        if (file.type === 'application/pdf') {
          return this.handlePdfFile(bucket, path, fileId, file, uploadResult);
        } else {
          return this.handleNonPdfFile(file, uploadResult);
        }
      })
    );
  }

  private uploadToStorage(bucket: string, filePath: string, file: File): Observable<any> {
    return from(this.client.storage.from(bucket).upload(filePath, file, { upsert: true }));
  }

  private handlePdfFile(bucket: string, path: string, fileId: string, file: File, uploadResult: any): Observable<UploadedFile> {
    const thumbnailPath = `${path}/${fileId}/${file.name.replace('.pdf', '_thumbnail.jpg')}`;

    return this.generatePdfThumbnail(file).pipe(
      mergeMap(thumbnailFile => this.uploadToStorage(bucket, thumbnailPath, thumbnailFile)),
      map(thumbnailUploadResult => ({
        name: file.name,
        fullPath: uploadResult.data?.fullPath,
        thumbnailPath: thumbnailUploadResult.data?.fullPath
      }))
    );
  }

  private handleNonPdfFile(file: File, uploadResult: any): Observable<UploadedFile> {
    return of({
      name: file.name,
      fullPath: uploadResult.data?.fullPath
    });
  }

  private generatePdfThumbnail(file: File): Observable<File> {
    return from(PdfToImg(file, {
      scale: 0.4,
      returnType: "blob",
      imgType: "jpg",
      pages: [1],
    })).pipe(
      map(pdfToImgResult => new File(pdfToImgResult, 'miniatura.jpg', { type: 'image/jpeg' }))
    );
  }

  downloadFile(fileUrl: string): Observable<Blob> {
    return new Observable<Blob>((observer) => {
      const parts = fileUrl.split('/');

      if (parts.length === 0) {
        observer.error('Invalid file URL');
        return;
      }

      const bucketName = parts[0];
      const path = parts.slice(1).join('/');

      this.client.storage
        .from(bucketName)
        .download(path)
        .then(({ data, error }) => {
          if (error) {
            observer.error(`Error downloading file: ${error.message}`);
            return;
          }

          const blob = new Blob([data]);
          observer.next(blob);
          observer.complete();
        })
        .catch((error) => {
          observer.error(`Error: ${error.message}`);
        });
    });
  }
}
